import { ApplicationSubmitted } from '~/pages/rental-application/screening/components/ApplicationSubmitted';

const ApplicationSubmittedPage = () => {
  return (
    <div class="relative flex h-full grow flex-col">
      <ApplicationSubmitted />
    </div>
  );
};

export default ApplicationSubmittedPage;
